import styled, { css } from "styled-components";
import { useState, FC, useEffect } from "react";
import { getSchedule, ScheduleType } from "../../contentful/getSchedule";

export const Schedule: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [schedule, setSchedule] = useState<ScheduleType[]>([]);

  const handleTabs = (index: number) => {
    setActiveTab(index);
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const res = await getSchedule();
        const sorted = res.sort((a, b) => {
          return a.location.localeCompare(b.location);
        });
        setSchedule(sorted);
      } catch (error) {
        console.log(error);
      }
    };

    if (!schedule.length) {
      fetchSchedule();
    }
  }, [schedule]);

  if (!schedule.length) {
    return null;
  }

  return (
    <Root>
      <h2 id="schedule" style={{ paddingLeft: 10 }}>
        KOMMANDE UTBILDNINGAR
      </h2>
      <NavWithTabs>
        {schedule.map((data, index) => (
          <Tab
            key={data.location}
            onClick={() => handleTabs(index)}
            active={activeTab === index}
          >
            {data.location}
          </Tab>
        ))}
      </NavWithTabs>
      <Table>
        <StyledThead>
          <tr>
            <TD align="left">UTBILDNING</TD>
            <TD align="left">DAG</TD>
            <TD align="left">DATUM</TD>
            <TD align="left">TID</TD>
          </tr>
        </StyledThead>

        {schedule.map((data, index) => (
          <tbody key={index}>
            {data.weeklyEducations.map((education) => (
              <TR
                key={education.service + education.date}
                active={activeTab === index}
              >
                <TD>{education.service}</TD>
                <TD align="left">{education.day}</TD>
                <TD align="left">{education.date}</TD>
                <TD align="left">{education.time}</TD>
              </TR>
            ))}
          </tbody>
        ))}
      </Table>
    </Root>
  );
};

const Root = styled.div`
  border-radius: 5px;
  box-shadow: 1px 3px 4px #959aa1;
  margin: var(--main-padding);
`;

const NavWithTabs = styled.nav`
  display: flex;
  align-items: flex-end;
  margin: 0 1rem;
  box-shadow: 0 0.5px 0 #959aa1;
`;

const Tab = styled.span<{ active: boolean }>`
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;

  ${(p) =>
    p.active &&
    css`
      background: white;
      color: var(--primary-clr);
      box-shadow: 0 2px 0 var(--primary-clr);
    `};
`;

const Table = styled.table`
  width: 100%;
  padding: 1rem;
`;

const StyledThead = styled.thead`
  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const TR: any = styled.tr`
  display: none;
  box-shadow: 0 -0.5px 0 #959aa1;

  ${(p: any) =>
    p.active &&
    css`
      display: table-row;

      @media screen and (max-width: 950px) {
        display: flex;
        flex-direction: column;
      }
    `}
`;

const TD = styled.td`
  padding: 0.6rem 0;

  :nth-child(2) {
    text-transform: capitalize;
  }
`;
